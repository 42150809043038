import deepmerge from 'deepmerge';
import { createTheme, Theme } from '@material-ui/core';
import { cartoThemeOptions } from '@carto/react-ui';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

const { palette, spacing } = createTheme(deepmerge(cartoThemeOptions, {}));

export interface CustomTheme extends Theme {
  palette: CustomPalette;
}

interface CustomPalette extends Palette {
  appBar: PaletteColor;
}

const customTheme = {
  palette: {
    primary: {
      light: '',
      main: '#dc0032',
      dark: '',
      contrastText: '',
    },
    secondary: {
      light: '',
      main: '#2388f7',
      dark: '',
      contrastText: '',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Roboto, sans-serif',
    },
    h2: {
      fontFamily: 'Roboto, sans-serif',
    },
    h3: {
      fontFamily: 'Roboto, sans-serif',
    },
    h4: {
      fontFamily: 'Roboto, sans-serif',
    },
    h5: {
      fontFamily: 'Roboto, sans-serif',
    },
    h6: {
      fontFamily: 'Roboto, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Roboto, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Roboto, sans-serif',
    },
    body1: {
      fontFamily: 'Roboto, sans-serif',
    },
    body2: {
      fontFamily: 'Roboto, sans-serif',
    },
    button: {
      fontFamily: 'Roboto, sans-serif',
    },
    caption: {
      fontFamily: 'Roboto, sans-serif',
    },
    overline: {
      fontFamily: 'Overpass Mono, sans-serif',
      letterSpacing: 0,
      textTransform: 'none',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: palette.common.white,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: palette.divider,
        color: '#dc0032',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#2388f7',
        },
      },
      input: {
        padding: spacing(2),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
};

const theme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default theme as CustomTheme;
