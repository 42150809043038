import { Credentials } from '@carto/react-api';
import { Data, Directions } from 'types';
import { executeApi } from 'utils/executeApi';

export async function getData({
  credentials,
  opts = {},
  data: { id },
}: {
  credentials: Credentials;
  opts: any;
  data: {
    id: string | undefined;
  };
}) {
  const url = `/web/depots/deliveries/${id}/consignee`;

  const data = await executeApi({ credentials, url, ...opts });
  return serializeData(data);
}

function serializeData({
  address_1,
  address_2,
  consignment_id,
  county,
  customer_id,
  delivery_id,
  depot_id,
  floor,
  google_address_string,
  google_lat,
  google_lng,
  id,
  last_modification,
  lat,
  lng,
  postal_code,
  town,
}: {
  address_1: string;
  address_2: string;
  consignment_id: string;
  county: string;
  customer_id: string;
  delivery_id: string;
  depot_id: number;
  floor: string;
  google_address_string: string;
  google_lat: number;
  google_lng: number;
  id: number;
  last_modification: string;
  lat: number;
  lng: number;
  postal_code: string;
  town: string;
}): Data {
  return {
    address: `${address_1 ? `${address_1},` : ''} ${
      address_2 ? `${address_2},` : ''
    } ${town ? `${town},` : ''} ${postal_code ? `${postal_code},` : ''} ${
      county ? county : ''
    }`,
    address1: address_1,
    address2: address_2,
    consignmentId: consignment_id,
    county: county,
    customerId: customer_id,
    deliveryId: delivery_id,
    depotId: depot_id,
    floor: floor,
    googleAddressString: google_address_string,
    googleLat: google_lat,
    googleLng: google_lng,
    id: id,
    lastModification: last_modification,
    geometry: {
      latitude: lat,
      longitude: lng,
    },
    postalCode: postal_code,
    town: town,
  };
}

export async function getGeocodeData({
  credentials,
  opts = {},
  data: { latitude, longitude, streetAddress, text },
}: {
  credentials: Credentials;
  opts: any;
  data: {
    latitude: string;
    longitude: string;
    streetAddress: number;
    text: string;
  };
}) {
  const url = `/web/geocode`;

  const params = {
    street_address: streetAddress,
    search: text ? text : latitude + ',' + longitude,
  };

  const data = await executeApi({ credentials, url, params, ...opts });
  return serializeGeocodeData(data.results);
}

function serializeGeocodeData(
  data: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    formatted_address: string;
    geometry: {
      bounds: {
        northeast: {
          lat: number;
          lng: number;
        };
        southwest: {
          lat: number;
          lng: number;
        };
      };
      location: {
        lat: number;
        lng: number;
      };
      location_type: string;
      viewport: {
        northeast: {
          lat: number;
          lng: number;
        };
        southwest: {
          lat: number;
          lng: number;
        };
      };
    };
    place_id: string;
    types: string[];
  }[],
): Directions[] {
  return data.map((d) => ({
    addressComponents: d.address_components.map((ad) => ({
      longName: ad.long_name,
      shortName: ad.short_name,
      types: ad.types,
    })),
    geometry: {
      //   bounds: {
      //     northeast: {
      //       latitude: d.geometry.bounds.northeast.lat,
      //       longitude: d.geometry.bounds.northeast.lng,
      //     },
      //     southwest: {
      //       latitude: d.geometry.bounds.southwest.lat,
      //       longitude: d.geometry.bounds.southwest.lng,
      //     },
      //   },
      //   location: {
      latitude: d.geometry.location.lat,
      longitude: d.geometry.location.lng,
      //   },
      //   locationType: d.geometry.location_type,
      //   viewport: {
      //     northeast: {
      //       latitude: d.geometry.viewport.northeast.lat,
      //       longitude: d.geometry.viewport.northeast.lng,
      //     },
      //     southwest: {
      //       latitude: d.geometry.viewport.southwest.lat,
      //       longitude: d.geometry.viewport.southwest.lng,
      //     },
      //   },
    },
    formattedAddress: d.formatted_address,
    placeId: d.place_id,
    types: d.types,
  }));
}

export async function getCheckDelivery({
  credentials,
  opts = {},
  data: { params, depotId },
}: {
  credentials: Credentials;
  opts: any;
  data: {
    params: any;
    depotId: number | undefined;
  };
}) {
  const url = `/web/depots/${depotId}/is_inside`;

  const data = await executeApi({ credentials, url, params, ...opts });
  return data;
}

export async function putDelivery({
  credentials,
  opts = {},
  data: { params, deliveryId },
}: {
  credentials: Credentials;
  opts: any;
  data: {
    params: any;
    deliveryId: string | undefined;
  };
}) {
  const url = `/web/depots/deliveries/${deliveryId}`;

  const data = await executeApi({
    credentials,
    url,
    method: 'PUT',
    params,
    ...opts,
  });
  return data;
}
