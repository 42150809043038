import { Navigate, useParams } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes';

export default function ProtectedRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const { accessToken } = useParams();

  const notAuthenticated = !accessToken;

  if (notAuthenticated) {
    return <Navigate to={ROUTE_PATHS.NOT_FOUND} />;
  }

  return children;
}
