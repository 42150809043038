import { useRoutes } from 'react-router-dom';
import {
  CssBaseline,
  Grid,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';
import LazyLoadRoute from 'components/common/LazyLoadRoute';
import theme from './theme';
import routes from './routes';
import GeolocationProvider from 'context/GeolocationProvider';
import AuthProvider from 'context/AuthProvider';
import DataProvider from 'context/DataProvider';
import SearchProvider from 'context/SearchProvider';

const useStyles = makeStyles(() => ({
  app: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
}));

export default function App() {
  const routing = useRoutes(routes);
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container direction='column' className={classes.app}>
        <AuthProvider>
          <DataProvider>
            <GeolocationProvider>
              <SearchProvider>
                <LazyLoadRoute>{routing}</LazyLoadRoute>
              </SearchProvider>
            </GeolocationProvider>
          </DataProvider>
        </AuthProvider>
      </Grid>
    </ThemeProvider>
  );
}
