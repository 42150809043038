import { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface DataContextState {
  deliveryId: string | undefined;
  setDeliveryId: Dispatch<SetStateAction<string | undefined>>;
  depotId: number | undefined;
  setDepotId: Dispatch<SetStateAction<number | undefined>>;
}

const initialState: DataContextState = {
  deliveryId: undefined,
  setDeliveryId: () => {},
  depotId: undefined,
  setDepotId: () => {},
};

export const DataContext = createContext(initialState);

export default function DataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [deliveryId, setDeliveryId] = useState<string | undefined>(undefined);
  const [depotId, setDepotId] = useState<number | undefined>(undefined);

  return (
    <DataContext.Provider
      value={{ deliveryId, setDeliveryId, depotId, setDepotId }}
    >
      {children}
    </DataContext.Provider>
  );
}
