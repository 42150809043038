import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from 'components/common/ProtectedRoute';
import DefaultView from 'components/common/DefaultView';

const Main = lazy(() => import('components/views/main/Main'));
const NotFound = lazy(() => import('components/views/NotFound'));
const Edit = lazy(() => import('components/views/edit/Edit'));
const Root = lazy(() => import('components/views/Root'));
// [hygen] Import views

export const ROUTE_PATHS = {
  DEFAULT: '/:accessToken',
  MAIN: '',
  NOT_FOUND: '/404',
  EDIT: 'edit',
  // [hygen] Add path routes
};

const routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    children: [
      { path: ROUTE_PATHS.MAIN, element: <Main /> },
      {
        path: ROUTE_PATHS.EDIT,
        element: (
          <DefaultView>
            <Edit />
          </DefaultView>
        ),
      },
      // [hygen] Add routes
    ],
  },
  {
    path: ROUTE_PATHS.NOT_FOUND,
    element: (
      <DefaultView>
        <NotFound />
      </DefaultView>
    ),
  },
  { path: '*', element: <Navigate to={ROUTE_PATHS.NOT_FOUND} /> },
];

export default routes;
