import { Credentials } from '@carto/react-api';

export const API = `${process.env.REACT_APP_API_URL}`;

/**
 * Executes a api request
 *
 * @param { Object } credentials - Local user credentials
 * @param { string } credentials.accessToken - Local token
 * @param { string } params - Params to request
 * @param { Object } queryParams - Params to request in URL
 * @param { Object } opts - Additional options for the HTTP request
 * @param { Object } opts.method - Method for execute HTTP
 * @returns { Object } - Data returned from the API
 */

interface CreateRequest {
  credentials: Credentials;
  url: string;
  method: 'GET' | 'POST' | 'PUT';
  params?: any;
  queryParams?: Array<any>;
  abortController?: AbortController;
  otherOptions?: any;
}

export async function executeApi({
  credentials,
  url,
  params,
  queryParams,
  method = 'GET',
  abortController,
  ...other
}: CreateRequest) {
  const request = createRequest({
    credentials,
    url,
    params,
    queryParams,
    method,
    abortController,
    ...other,
  });
  const response = await fetch(request);

  if (!response.ok) {
    const error = await response.text();
    return Promise.reject(error);
  }

  if (response.status !== 204) {
    const data = await response.json();

    return data;
  }
}

/**
 * Create an 'api' request
 * (using GET or POST request, depending on method)
 */
function createRequest({
  credentials,
  url,
  params,
  queryParams,
  method = 'GET',
  abortController,
  ...otherOptions
}: CreateRequest) {
  const base = API + url;

  let rawParams = {
    ...otherOptions,
  };

  const requestOpts: any = { ...otherOptions };
  if (abortController) {
    requestOpts['signal'] = abortController.signal;
  }

  let request = ({
    url,
    params,
    requestOpts,
  }: {
    url: string;
    params: Array<any> | undefined;
    requestOpts: any;
  }) => requestWithBody(method, url, params, credentials, requestOpts);

  if (method === 'GET') {
    rawParams = {
      ...rawParams,
      ...params,
    };

    request = ({ url, requestOpts }) =>
      getRequest(url, credentials, requestOpts);
  }

  const encodedParams = Object.entries({
    ...rawParams,
    ...queryParams,
  })
    .map(([key, value]) => encodeParameter(key, value))
    .filter(Boolean);

  const generateUrl = generateApiUrl({
    url: base,
    parameters: encodedParams,
  });

  return request({ url: generateUrl, params, requestOpts });
}

function requestWithBody(
  method = 'POST',
  url: string,
  payload = {},
  credentials: Credentials,
  opts: any,
) {
  // @ts-ignore
  const { accessToken } = credentials;
  return new Request(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(!!accessToken && { authorization: 'JWT ' + accessToken }),
    },
    body: JSON.stringify(payload),
    ...opts,
  });
}

// TODO: Change all functions below this comment with carto lib functions when exported

/**
 * Generate a valid API url for a request
 * @param {} param0
 */
function generateApiUrl({
  url,
  parameters,
}: {
  url: string;
  parameters: Array<any>;
}) {
  const base = url;

  if (!parameters || parameters.length === 0) {
    return base;
  }

  return `${base}?${parameters.join('&')}`;
}

/**
 * Simple GET request
 */
function getRequest(url: string, credentials: Credentials, opts: any) {
  // @ts-ignore
  const { accessToken } = credentials;
  return new Request(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...(!!accessToken && { authorization: 'JWT ' + accessToken }),
    },
    ...opts,
  });
}

/**
 * Simple encode parameter
 */
function encodeParameter(name: string, value: Array<any> | string | number) {
  if (Array.isArray(value)) {
    return value.map((v) => `${name}=${encodeURIComponent(v)}`).join('&');
  }

  if (value === undefined || value === null) {
    return '';
  }

  return `${name}=${encodeURIComponent(value)}`;
}
