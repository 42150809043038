import { IconButton, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReplayIcon from '@material-ui/icons/Replay';
import { useContext } from 'react';
import { SearchContext } from 'context/SearchProvider';
import { GeolocationContext } from 'context/GeolocationProvider';
import { useDispatch } from 'react-redux';
import { setViewState } from '@carto/react-redux';
import useData from 'hooks/useData';

export default function Tools() {
  const dispatch = useDispatch();
  const { open, setOpen } = useContext(SearchContext);
  const { originalGeolocation, setAddress } = useContext(GeolocationContext);
  const { data } = useData();

  const handleSearch = () => {
    setOpen(!open);
  };

  const handleReset = () => {
    setAddress(data.address);
    dispatch(
      setViewState({
        latitude: originalGeolocation?.latitude,
        longitude: originalGeolocation?.longitude,
        zoom: 12,
      }),
    );
  };

  return (
    <>
      <Tooltip title='Search' aria-label='Search' placement='bottom'>
        <IconButton onClick={handleSearch}>
          <SearchIcon color='action' />
        </IconButton>
      </Tooltip>
      <Tooltip
        title='Reset position'
        aria-label='Reset position'
        placement='bottom'
      >
        <IconButton onClick={handleReset}>
          <ReplayIcon color='action' />
        </IconButton>
      </Tooltip>
    </>
  );
}
