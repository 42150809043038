import { useContext, useEffect } from 'react';
import { Credentials } from '@carto/react-api';
import { DataContext } from 'context/DataProvider';
import { getData } from 'data/models/dataModel';
import useDataFetch from './useDataFetch';
import useGeolocation from './useGeolocation';
import { Data, Direction } from 'types';
import { GeolocationContext } from 'context/GeolocationProvider';

const INITIAL_DATA: Direction = {
  geometry: {
    latitude: 53.52288001,
    longitude: -7.59936519,
    zoom: 7,
  },
};

export default function useData(): { data: Data } {
  const { setOriginalGeolocation } = useGeolocation();
  const { deliveryId, setDepotId } = useContext(DataContext);
  const { setFloor } = useContext(GeolocationContext);

  const { data }: { data: Data } = useDataFetch({
    initialData: INITIAL_DATA,
    callback: (credentials: Credentials, opts: any) =>
      getData({ credentials, opts, data: { id: deliveryId } }),
    enabled: !!deliveryId,
    key: 'data',
  });

  useEffect(() => {
    setOriginalGeolocation({
      latitude: data.geometry.latitude || INITIAL_DATA.geometry?.latitude,
      longitude: data.geometry.longitude || INITIAL_DATA.geometry?.longitude,
      zoom: !data.geometry.longitude ? INITIAL_DATA.geometry?.zoom : 12,
    });
    setFloor(data.floor);
    setDepotId(data.depotId);
  }, [data, setOriginalGeolocation, setFloor, setDepotId]);

  return {
    data,
  };
}
