import { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface AuthContextState {
  accessToken: string | undefined;
  setAccessToken: Dispatch<SetStateAction<string | undefined>>;
}

const initialState: AuthContextState = {
  accessToken: undefined,
  setAccessToken: () => {},
};

export const AuthContext = createContext(initialState);

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
}
