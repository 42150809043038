import { useNavigate } from 'react-router';
import useSWR from 'swr';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AuthContext } from 'context/AuthProvider';
import { ROUTE_PATHS } from 'routes';

export function useStickySWR(...args: any) {
  const val = useRef();

  const { data, isValidating, error, ...rest } = useSWR(...args);

  if (data !== undefined) {
    val.current = data;
  }

  return {
    ...rest,
    isValidating,
    error,
    data: val.current,
  };
}

const abortControllers = new Map();

/**
 * @param {*} opts - configuration options
 * @param {any} opts.initialData - data to return before fetching is done. null by default.
 * @param {function} opts.callback - function to call for data fetching. By default, it's called with the arguments: function moduleMethod(metadata, credentials, { abortController }) { ... }
 * @param {any[]} opts.dependencies - dependencies of the callback. Each time one of the dependencies changes, the callback is called.
 * @param {boolean} opts.use404 - flag that indicates if we should redirect the app to /404 if the returned data is falsy
 * @param {boolean} opts.enabled - flag that indicates if the effect is executed.
 * @param {string} opts.errorMsg - message to use for the error snackbar in the `catch` block of the fetching process
 * @param {boolean} opts.useSticky - if true, stickySwr is used. That means that meanwhile the callback is being executed, the hook won't return the initialData but the previous value fetched by the callback. Only the first time (when no previous value exists) the initialData will be returned. If it's not sticky, each time a new call to callback is being processed, the initialData is returned.
 * @param {Object} opts.swrOptions - options used in SWR. For more details: https://github.com/vercel/swr#options. By default, we are already using some options, check the code.
 * @returns {Object} - object with: data, error, loading and mutate.
 *
 * example usage:
 * ```
 const { data: details } = useDataFetch({
    initialData: {},
    use404: true,
    errorMsg: '[FeatureDetails.js] Error fetching feature details'
    callback: (metadata, credentials, { abortController }) => getFeatureDetails(metadata, credentials, { abortController, featureId }),
    dependencies: [featureId],
  })
 * ```
 */

interface UseDataFetch {
  key?: String;
  initialData?: unknown;
  use404?: boolean;
  errorMsg?: string;
  useSticky?: boolean;
  callback: Function;
  dependencies?: any[];
  swrOptions?: Object;
  enabled?: boolean;
}

export default function useDataFetch({
  key,
  initialData = null,
  use404 = false,
  errorMsg = '[useDataFetch] Error in data fetch',
  enabled = true,
  dependencies = [],
  callback = () => {},
  useSticky = true,
  swrOptions = {},
}: UseDataFetch) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useContext(AuthContext);

  // Easiest way to define easily a unique ID.
  // If you want to use the SWR cache, do NOT copy & paste useDataFetch code
  // between different components, instead use a hook.
  if (!key) {
    key = callback.toString();
  }

  // Sticky returns the previous fetched value while the request is being made.
  const useSWRAlias = useSticky ? useStickySWR : useSWR;

  const {
    data = initialData,
    error,
    isValidating,
    mutate,
  } = useSWRAlias(
    enabled ? [key, ...dependencies] : null,
    () => {
      if (abortControllers.has(key)) {
        abortControllers.get(key).abort();
      }
      const abortController = new AbortController();
      abortControllers.set(key, abortController);
      return callback({ accessToken }, { abortController });
    },
    {
      // To disable repeated request with same key
      revalidateOnFocus: false,
      dedupingInterval: 3600000,
      // To disable repeat failed request
      errorRetryCount: 0,
      ...swrOptions,
    },
  );

  useEffect(() => {
    if (error) {
      if (use404) {
        navigate(ROUTE_PATHS.NOT_FOUND, { replace: true });
      }
    }
  }, [dispatch, errorMsg, navigate, error, use404]);

  return { data, error, loading: isValidating, mutate };
}
