import { useContext, useEffect, useRef } from 'react';
import {
  GeolocationContext,
  GeolocationContextState,
} from 'context/GeolocationProvider';
import { useDispatch } from 'react-redux';
import { setViewState } from '@carto/react-redux';

export default function useGeolocation() {
  const dispatch = useDispatch();
  const {
    geolocation,
    setGeolocation,
    setUserGeolocation,
    originalGeolocation,
    setOriginalGeolocation,
    error,
    setError,
  }: GeolocationContextState = useContext(GeolocationContext);
  const _originalGeolocation = useRef(originalGeolocation);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (data) => {
          setUserGeolocation({
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
          });
        },
        (err) => {
          setError(true);
        },
      );
    } else {
      setError(true);
    }
  }, [setUserGeolocation, setError]);

  useEffect(() => {
    if (
      JSON.stringify(originalGeolocation) !==
      JSON.stringify(_originalGeolocation.current || {})
    ) {
      _originalGeolocation.current = originalGeolocation;
      dispatch(
        setViewState({
          latitude: originalGeolocation?.latitude,
          longitude: originalGeolocation?.longitude,
          zoom: originalGeolocation?.zoom || 12,
        }),
      );
    }
  }, [dispatch, originalGeolocation]);

  const dirty = originalGeolocation !== geolocation;

  return {
    geolocation,
    setGeolocation,
    setOriginalGeolocation,
    error,
    dirty,
  };
}
