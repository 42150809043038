import { CartoBasemapsNames, GOOGLE_SATELLITE } from '@carto/react-basemaps';
// @ts-ignore
import { API_VERSIONS } from '@deck.gl/carto';

interface InitialState {
  viewState: {
    latitude: number;
    longitude: number;
    zoom: number;
    pitch: number;
    bearing: number;
    dragRotate: boolean;
  };
  basemap: CartoBasemapsNames;
  credentials: {
    apiVersion: API_VERSIONS;
    apiBaseUrl: string;
  };
  googleApiKey: string;
  oauth: {
    domain: string;
    clientId: string;
    scopes: string[];
    audience: string;
    authorizeEndPoint: string;
  } | null;
}

export const initialState: InitialState = {
  viewState: {
    latitude: 31.802892,
    longitude: -103.007813,
    zoom: 2,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  // @ts-ignore
  basemap: GOOGLE_SATELLITE,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
  },
  googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API as string, // only required when using a Google Basemap,
  oauth: null,
  // oauth: {
  //   domain: 'auth.carto.com',
  //   // Type here your application client id
  //   clientId: '',
  //   scopes: [
  //     'read:current_user',
  //     'update:current_user',
  //     'read:connections',
  //     'write:connections',
  //     'read:maps',
  //     'write:maps',
  //     'read:account',
  //     'admin:account',
  //   ],
  //   audience: 'carto-cloud-native-api',
  //   authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  // },
};
