import { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface SearchContextState {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const initialState: SearchContextState = {
  open: false,
  setOpen: () => {},
};

export const SearchContext = createContext(initialState);

export default function SearchProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <SearchContext.Provider value={{ open, setOpen }}>
      {children}
    </SearchContext.Provider>
  );
}
