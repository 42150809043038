import { createContext, Dispatch, SetStateAction, useState } from 'react';

export interface Geolocation {
  latitude: number | undefined;
  longitude: number | undefined;
  zoom?: number;
}

export interface GeolocationContextState {
  geolocation: Geolocation | undefined;
  setGeolocation: Dispatch<SetStateAction<Geolocation | undefined>>;
  userGeolocation: Geolocation | undefined;
  setUserGeolocation: Dispatch<SetStateAction<Geolocation | undefined>>;
  originalGeolocation: Geolocation | undefined;
  setOriginalGeolocation: Dispatch<SetStateAction<Geolocation | undefined>>;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  address: string | undefined;
  setAddress: Dispatch<SetStateAction<string | undefined>>;
  floor: string;
  setFloor: Dispatch<SetStateAction<string>>;
  pin: boolean;
  setPin: Dispatch<SetStateAction<boolean>>;
}

const initialState: GeolocationContextState = {
  geolocation: undefined,
  setGeolocation: () => {},
  userGeolocation: undefined,
  setUserGeolocation: () => {},
  originalGeolocation: undefined,
  setOriginalGeolocation: () => {},
  error: false,
  setError: () => {},
  address: undefined,
  setAddress: () => {},
  floor: '',
  setFloor: () => {},
  pin: false,
  setPin: () => {},
};

export const GeolocationContext = createContext(initialState);

export default function GeolocationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [geolocation, setGeolocation] = useState<Geolocation | undefined>(
    undefined,
  );
  const [userGeolocation, setUserGeolocation] = useState<
    Geolocation | undefined
  >(undefined);
  const [originalGeolocation, setOriginalGeolocation] = useState<
    Geolocation | undefined
  >(undefined);
  const [error, setError] = useState(false);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [floor, setFloor] = useState<string>('');
  const [pin, setPin] = useState(false);

  return (
    <GeolocationContext.Provider
      value={{
        geolocation,
        setGeolocation,
        userGeolocation,
        setUserGeolocation,
        error,
        setError,
        originalGeolocation,
        setOriginalGeolocation,
        address,
        setAddress,
        floor,
        setFloor,
        pin,
        setPin,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
}
