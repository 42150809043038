import {
  AppBar,
  Hidden,
  Grid,
  Toolbar,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ReactComponent as DPDLogo } from 'assets/img/dpd-logo.svg';
import { ReactComponent as DPDLogoXS } from 'assets/img/dpd-logo-xs.svg';
import { useTheme } from '@material-ui/styles';
import { CustomTheme } from 'theme';
import Tools from './Tools';

const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'none',
    zIndex: theme.zIndex.modal + 1,
    overflow: 'hidden',
  },
  title: {
    '& h1': {
      display: 'flex',
      ...theme.typography.subtitle2,
      color: theme.palette.primary.main,
      alignItems: 'center',
      letterSpacing: '0.009em',
      [theme.breakpoints.up('md')]: {
        ...theme.typography.h5,
        fontWeight: 300,
      },
    },
    '& svg': {
      marginTop: theme.spacing(0.2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginRight: theme.spacing(3),
      },
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const { breakpoints }: CustomTheme = useTheme();
  const desktop = useMediaQuery(breakpoints.up('sm'));

  return (
    <AppBar position='static' className={classes.header}>
      <Toolbar variant={`${desktop ? 'regular' : 'dense'}`}>
        <div className={classes.title}>
          <Typography component='h1' noWrap>
            <Hidden smUp>
              <DPDLogoXS />
            </Hidden>
            <Hidden xsDown>
              <DPDLogo />
            </Hidden>
            <AppName />
          </Typography>
        </div>
        <Grid container direction='row' justifyContent='flex-end'>
          <Tools />
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

function AppName() {
  return <>Locate your place</>;
}
